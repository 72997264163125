@import '~@uipath/apollo-icons/public/apollo.css';
@import "@uipath/apollo-lab/core/style.css";
body {
  margin: 0;
  padding: 0;
  font-family: noto-sans, 'Noto Sans', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
}

#root {
  overflow: hidden;
  height: 100%;
}

svg {
  overflow: hidden;
  vertical-align: inherit;
}

.modal-open {
  padding: 0 !important;
  overflow-y: auto;
}

label {
  margin-bottom: unset;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
